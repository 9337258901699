import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import { ItemTypes } from 'store/items/types'
import PngCoverLetter from 'assets/images/cover-letter.png'
import PngInterviewCoaching from 'assets/images/interview-coaching.png'
import PngLinkedIn from 'assets/images/linkedin.png'
import PngRecruiter from 'assets/images/recruiter.png'
import PngResume from 'assets/images/resume.png'
import PngResumeDistribution from 'assets/images/resume-distribution.png'
import PngResumeRefresh from 'assets/images/resume-refresh.png'
import PngThankYouLetter from 'assets/images/thank-you-letter.png'
import SvgAcademicResume from 'assets/images/academic-resume.svg'
import SvgCoverLetter from 'assets/images/cover-letter.svg'
import SvgFederalResume from 'assets/images/federal-resume.svg'
import SvgInterviewCoaching from 'assets/images/interview-coaching.svg'
import SvgLinkedIn from 'assets/images/linkedIn.svg'
import SvgMilitaryResume from 'assets/images/military-resume.svg'
import SvgPhoneConsultation from 'assets/images/phone-consultation.svg'
import SvgRecruiter from 'assets/images/recruiter.svg'
import SvgResume from 'assets/images/resume.svg'
import SvgResumeDistribution from 'assets/images/resume-distribution.svg'
import SvgThankYouLetter from 'assets/images/thank-you-letter.svg'
import Svg48HourRush from 'assets/images/48-hour-rush.svg'
import Svg60DayGuarantee from 'assets/images/60-day-guarantee.svg'

export interface OrderItemIconProps {
  itemType: ItemTypes
  itemName: string
  className?: string
  drawer?: boolean
}

const useStyles = makeStyles((theme) =>
  createStyles({
    img: {
      maxHeight: 'inherit',
      maxWidth: 'inherit',
    },
  })
)

const OrderItemIcon: React.FC<OrderItemIconProps> = ({
  itemType,
  itemName,
  className,
  drawer = false,
}) => {
  const classes = useStyles()
  let icon = SvgResume

  switch (itemType) {
    case ItemTypes.CurriculumVitae:
      icon = SvgAcademicResume
      break
    case ItemTypes.CoverLetter:
      icon = drawer ? PngCoverLetter : SvgCoverLetter
      break
    case ItemTypes.Resume:
      // @TODO This is a hack and might not be necessary in the future.
      if (itemName.toLowerCase().indexOf('federal') > -1) {
        icon = SvgFederalResume
      } else if (itemName.toLowerCase().indexOf('military') > -1) {
        icon = SvgMilitaryResume
      } else if (itemName.toLowerCase().indexOf('refresh') > -1) {
        icon = drawer ? PngResumeRefresh : SvgResume
      } else {
        icon = drawer ? PngResume : SvgResume
      }
      break
    case ItemTypes.InterviewPrep:
      icon = drawer ? PngInterviewCoaching : SvgInterviewCoaching
      break
    case ItemTypes.LinkedInDocument:
      icon = drawer ? PngLinkedIn : SvgLinkedIn
      break
    case ItemTypes.PhoneCall:
      icon = SvgPhoneConsultation
      break
    case ItemTypes.RecruiterService:
      icon = drawer ? PngRecruiter : SvgRecruiter
      break
    case ItemTypes.PostingService:
      icon = drawer ? PngResumeDistribution : SvgResumeDistribution
      break
    case ItemTypes.ThankYouLetter:
      icon = drawer ? PngThankYouLetter : SvgThankYouLetter
      break
    case ItemTypes.RushOrder:
      icon = Svg48HourRush
      break
    case ItemTypes.Guarantee:
      icon = Svg60DayGuarantee
      break
    default:
      icon = drawer ? PngResume : SvgResume
  }

  return (
    <img
      alt={`${itemName} icon`}
      className={clsx(classes.img, className)}
      src={icon}
    />
  )
}

export default OrderItemIcon
