import React from 'react'
import clsx from 'clsx'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Badge from '@material-ui/core/Badge'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'

import GhostLink, { GhostLinkProps } from 'components/shared/GhostLink'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { colors } from 'styles'

interface Props {
  copy: string
  link: string
  iconClass?: string | null
  linkClass?: string | null
  textClass?: string | null
  showBadge?: boolean
  GhostLinkProps?: Partial<GhostLinkProps>
  chipText?: string | null
  chipClass?: string
}

const useStyles = makeStyles((theme) =>
  createStyles({
    badge: {},
    listItem: {
      color: theme.palette.secondary.light,
      padding: '.6em 0',
      '&:hover': {
        color: theme.palette.primary.dark,

        '& span': {
          color: 'inherit',
        },
      },
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    listItemIcon: {
      color: 'inherit',
      height: 'auto',
      marginRight: '.75em',
      minWidth: '1em',
      objectPosition: 'center',
      width: '1em',
    },
    listItemText: {
      margin: 0,
    },
    typography: {
      color: theme.palette.secondary.main,
      marginTop: '.25em',
    },
    active: {
      color: theme.palette.primary.dark,
      '& span': {
        color: 'inherit',
      },
    },
    chip: {
      marginLeft: '-3rem',
      marginTop: '0.25rem',
      padding: '0.25rem 0.25rem 0.1rem 0.25rem',
      backgroundColor: colors.orange[600],
      borderRadius: '0.25rem',
    },
    chipText: {
      fontSize: '0.75rem',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      textAlign: 'center',
      color: colors.orange[500],
    },
  })
)

const IconListLink: React.FC<Props> = ({
  children,
  copy,
  link,
  iconClass,
  linkClass,
  textClass,
  showBadge = false,
  GhostLinkProps = {},
  chipText,
  chipClass,
}) => {
  const classes = useStyles()

  return (
    <ListItem
      className={clsx(classes.listItem, linkClass)}
      component={(props) => (
        <GhostLink
          exact
          activeClassName={classes.active}
          {...GhostLinkProps}
          {...props}
        />
      )}
      to={link}
    >
      <ListItemIcon className={clsx(classes.listItemIcon, iconClass)}>
        <ListItemBadge
          className={classes.badge}
          invisible={!showBadge}
          color="primary"
          aria-label="you have unread messages"
        >
          {children}
        </ListItemBadge>
      </ListItemIcon>
      <ListItemText
        className={clsx(classes.listItemText, textClass)}
        primaryTypographyProps={{
          className: classes.typography,
          variant: 'h5',
        }}
      >
        {copy}
      </ListItemText>
      {chipText && (
        <Box className={clsx(classes.chip, chipClass)}>
          <Typography variant="h2" className={classes.chipText}>
            {chipText}
          </Typography>
        </Box>
      )}
    </ListItem>
  )
}

const ListItemBadge = withStyles((theme) => ({
  badge: {
    minWidth: '10px',
    width: '10px',
    height: '10px',
    padding: 0,
  },
  colorPrimary: {
    backgroundColor: theme.palette.error.main,
  },
}))(Badge)

export default IconListLink
