import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import { colors } from 'styles'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Checkout from 'components/Navigation/Checkout'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import CheckoutDrawer from 'components/Checkout/CheckoutDrawer'
import { GhostLinkButton, GhostRedirect } from 'components/shared/GhostLink'
import { generatePath } from 'react-router-dom'
import useBrand from 'hooks/useBrand'
import Logo, { LogoProps } from 'components/shared/Logo'
import Button from '@material-ui/core/Button'

interface Props {
  isLoggedIn?: boolean
}

const ProductPageShell: React.FC<Props> = ({ children, isLoggedIn }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const brand = useBrand()

  const [redirect, setRedirect] = useState(false)

  const onCheckoutClick = useCallback(async () => {
    setRedirect(true)
  }, [setRedirect])

  const logoProps: LogoProps = { to: t('routes.home') }

  if (brand.home_url) {
    logoProps.to = undefined
    logoProps.href = brand.home_url
  }

  if (redirect) {
    return <GhostRedirect to={generatePath(t(`routes.upgrade`))} push />
  }
  return (
    <>
      <Paper className={classes.paper}>
        {!isLoggedIn && (
          <Box className={classes.headerContainer}>
            <Logo className={classes.logo} {...logoProps} />{' '}
            <Button
              className={classes.button}
              href={brand.home_url}
              variant="text"
            >
              {t('components.Auth.Login.back')}
            </Button>
          </Box>
        )}
        {isLoggedIn && (
          <Box className={classes.headerContainer}>
            <GhostLinkButton
              variant="text"
              to={t('routes.upgrade')}
              className={classes.ghostLink}
            >
              <ChevronLeftIcon className={classes.icon} />
              <Typography variant="body2" className={classes.backLink}>
                {t(`components.Product.continueShopping`)}
              </Typography>
            </GhostLinkButton>
            <Box className={classes.drawerContainer}>
              <Checkout onCheckoutClick={onCheckoutClick} />
            </Box>
          </Box>
        )}

        <Box className={classes.childrenContainer}>{children}</Box>
      </Paper>
      <CheckoutDrawer
        classDrawer={classes.drawer}
        classDrawerMinimized={classes.drawerMinimized}
        classModal={classes.modal}
        classModalMinimized={classes.modalMinimized}
        drawerMax={false}
        minimizedNav={false}
      />
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      position: 'fixed',
      top: '0',
      left: '0',
      padding: '0.5rem 4rem 0.5rem 4rem',
      [theme.breakpoints.down('sm')]: {
        padding: '0.5rem 2rem 0.5rem 2rem',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0.5rem 1rem 0.5rem 1rem',
      },
      backgroundColor: colors.white[100],
      borderBottom: `solid ${colors.gray[600]} 1px `,
      minHeight: '5rem',
    },
    childrenContainer: {
      padding: '2rem 4rem 2rem 4rem',
      maxWidth: '1280px',
      marginTop: '4.375rem',
      [theme.breakpoints.down('sm')]: {
        padding: '1rem 2rem 2rem 2rem',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0rem 0rem 2rem 0rem',
      },
    },
    drawer: {
      borderRadius: '0',
      borderTop: '0',
      height: '100%',
      marginTop: '0',
      maxHeight: '100%',
      paddingBottom: '0',
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: '1.58em',
      width: '13em',
      transition: 'width ease-out .2s',
      [theme.breakpoints.down('sm')]: {
        width: '16.6em',
      },
    },
    drawerMinimized: {
      width: '4.8em',
    },
    modal: {
      height: '48em',
      maxHeight: '100%',
      width: '13em',
    },
    modalMinimized: {
      width: '4.8em',
    },
    drawerContainer: {
      margin: '0.5rem 0rem 0rem 2rem',
    },
    divider: {
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '0rem',
      },
    },
    backLink: {
      fontWeight: 600,
      color: colors.blue[700],
    },
    icon: {
      color: colors.blue[700],
      fontSize: '1.6rem',
      marginRight: '0.5rem',
    },
    ghostLink: {
      display: ' flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    logo: {
      fontSize: '1em',
      width: '6.9em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxHeight: '1.2em',

      '& img': {
        maxHeight: '1.2em',
        margin: '0 auto',
      },
    },
    button: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  })
)

export default ProductPageShell
