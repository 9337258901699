import React from 'react'
import useBrand from 'hooks/useBrand'
import { useTranslation } from 'react-i18next'

import ContentHeader from 'components/Content/ContentHeader'
import ContentVideo from 'components/Content/ContentVideo'
import FlashErrorBoundary from 'components/shared/Errors'
import PageShell from 'pages/PageShell'
import Quote from 'components/Content/Quote'
import TextContainer from 'components/Content/TextContainer'
import ArticleTrans from 'components/Content/ArticleTrans'

import GetToKnowImg from 'assets/images/get-to-know-large.png'

const Article2: React.FC = () => {
  const brand = useBrand()
  let product = 'resume'
  if (brand.default_resume_product) {
    product =
      brand.default_resume_product === 'CV'
        ? brand.default_resume_product
        : brand.default_resume_product.toLowerCase()
  }
  const { t } = useTranslation()

  return (
    <PageShell content={true}>
      <FlashErrorBoundary>
        <ContentHeader
          date={t([
            `pages.Article2.${brand.name}.date`,
            'pages.Article2.default.date',
          ])}
          img={GetToKnowImg}
          imgAlt={t([
            `pages.Article2.${brand.name}.imgAlt`,
            'pages.Article2.default.imgAlt',
          ])}
          title={t([
            `pages.Article2.${brand.name}.title`,
            'pages.Article2.default.title',
          ])}
        />
        <TextContainer>
          <ArticleTrans
            i18nKeys={[
              `pages.Article2.${brand.name}.text`,
              `pages.Article2.default.text`,
            ]}
            components={{
              ContentVideo: (
                <ContentVideo url="https://www.youtube.com/watch?v=B9TpmrZ2EzE" />
              ),
              Quotes: (
                <>
                  <Quote
                    author={t([
                      `pages.Article2.${brand.name}.quote1.author`,
                      'pages.Article2.default.quote1.author',
                    ])}
                    quote={t(
                      [
                        `pages.Article2.${brand.name}.quote1.quote`,
                        'pages.Article2.default.quote1.quote',
                      ],
                      {
                        product: product,
                      }
                    )}
                    title={t(
                      [
                        `pages.Article2.${brand.name}.quote1.title`,
                        'pages.Article2.default.quote1.title',
                      ],
                      {
                        brand: brand.name,
                        product: product,
                      }
                    )}
                  />
                  <Quote
                    author={t([
                      `pages.Article2.${brand.name}.quote2.author`,
                      'pages.Article2.default.quote2.author',
                    ])}
                    quote={t([
                      `pages.Article2.${brand.name}.quote2.quote`,
                      'pages.Article2.default.quote2.quote',
                    ])}
                    title={t([
                      `pages.Article2.${brand.name}.quote2.title`,
                      'pages.Article2.default.quote2.title',
                    ])}
                  />
                  <Quote
                    author={t([
                      `pages.Article2.${brand.name}.quote3.author`,
                      'pages.Article2.default.quote3.author',
                    ])}
                    quote={t(
                      [
                        `pages.Article2.${brand.name}.quote3.quote`,
                        'pages.Article2.default.quote3.quote',
                      ],
                      {
                        brand: brand.name,
                        product: product,
                      }
                    )}
                    title={t([
                      `pages.Article2.${brand.name}.quote3.title`,
                      'pages.Article2.default.quote3.title',
                    ])}
                  />
                  <Quote
                    author={t([
                      `pages.Article2.${brand.name}.quote4.author`,
                      'pages.Article2.default.quote4.author',
                    ])}
                    quote={t(
                      [
                        `pages.Article2.${brand.name}.quote4.quote`,
                        'pages.Article2.default.quote4.quote',
                      ],
                      {
                        product: product,
                      }
                    )}
                    title={t([
                      `pages.Article2.${brand.name}.quote4.title`,
                      'pages.Article2.default.quote4.title',
                    ])}
                  />
                </>
              ),
            }}
          />
        </TextContainer>
      </FlashErrorBoundary>
    </PageShell>
  )
}

export default Article2
