import React from 'react'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import { colors } from 'styles/index'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import CloseIcon from '@material-ui/icons/Close'
import DescriptionIcon from '@material-ui/icons/Description'
import FolderIcon from '@material-ui/icons/Folder'
import IconButton from '@material-ui/core/IconButton'
import StarIcon from '@material-ui/icons/Star'
import { useTranslation } from 'react-i18next'

import SvgHome from 'components/shared/icons/Home'
import SvgLogout from 'components/shared/icons/Logout'
import IconListLink from 'components/shared/IconListLink'
import Logo from 'components/shared/Logo'
import RailUpsellCardContainer from 'components/Upsells/RailUpsellCardContainer'
import useUser from 'hooks/useUser'
import useUnreadMessages from 'hooks/useUnreadMessages'
import { NoOpErrorBoundary } from 'components/shared/Errors'
import StarGradient from 'assets/images/star-gradient.gif'
import useExperiment from 'hooks/useExperiment'
import useBrand from 'hooks/useBrand'

interface Props {
  drawerMax: boolean
  minimized?: boolean
  pageTitle?: string
  toggleDrawerMax?: any
}

const useStyles = makeStyles((theme) =>
  createStyles({
    arrowForward: {
      fontSize: '1.25em',
    },
    backdrop: {
      display: 'none',
    },
    closeButton: {
      position: 'absolute',
      right: '1em',
      top: '1.8em',
      zIndex: 100,
    },
    closeIcon: {
      fontSize: '1.5em',
    },
    divider: {
      marginTop: '1em',
    },
    iconButton: {
      borderTop: `1px solid ${colors.gray[600]}`,
      borderRadius: '0',
      height: '4.9em',
    },
    list: {
      color: theme.palette.info.main,
      padding: '1.75em 2em 0',

      '& > *': {
        height: '3.1rem',
      },
    },
    listAccount: {
      paddingTop: '1em',
    },
    listGroup: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
    },
    listBottom: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'flex-end',
    },
    listGroupContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
      margin: '0',
    },
    listItem: {
      color: 'inherit',
      padding: '.6em 0',
    },
    listItemIcon: {
      color: 'inherit',
      height: '1.03em',
      minWidth: '2.5em',
      objectPosition: 'left',
    },
    listItemTextMinimized: {
      display: 'none',
    },
    logo: {
      fontSize: '1em',
      marginLeft: '2em',
      marginRight: '2em',
      textDecoration: 'none',

      '& img': {
        maxHeight: '2.2em',
        maxWidth: '100%',
      },

      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    logoMinimized: {
      marginLeft: '1.2em',
    },
    starIcon: {
      height: '1.6em',
      width: '1.6em',
      marginLeft: '-0.25rem',
    },
    svgIcon: {
      height: '1.2rem',
      width: '1.2rem',
    },
    welcome: {
      fontSize: '1.25em',
      marginLeft: '1.6em',
      marginRight: '1.6em',
    },
    wideIcon: {
      marginRight: '.62em',
      width: '1.13em',
    },
  })
)

const NavMenu: React.FC<Props> = ({
  drawerMax,
  minimized = false,
  toggleDrawerMax,
}) => {
  const { t } = useTranslation()
  const customer = useUser()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { unreadMessageCount } = useUnreadMessages()
  const upgradeActive = window.location.pathname.includes(t('routes.upgrade'))
  const { name } = useBrand()
  const classes = useStyles({ name })
  // Product Page Experiment
  const isInProductPageExperiment = useExperiment({
    experimentLabel: 'Product Pages Portal',
    caseDescriptor: 'test-product-page',
  })

  return (
    <>
      {!drawerMax && (
        <Logo
          className={clsx(
            classes.logo,
            minimized ? classes.logoMinimized : null
          )}
          minimized={minimized}
        />
      )}
      {(isMobile || drawerMax) && (
        <Typography className={classes.welcome} variant="subtitle1">
          {t('components.NavMenu.greeting', { name: customer.first_name })}
        </Typography>
      )}
      {minimized && drawerMax && (
        <IconButton className={classes.closeButton}>
          <CloseIcon
            className={classes.closeIcon}
            color="secondary"
            onClick={toggleDrawerMax(false)}
          />
        </IconButton>
      )}
      <div className={classes.listGroupContainer}>
        <div className={classes.listGroup}>
          <List className={classes.list} component="nav">
            <IconListLink
              link={t('routes.home')}
              copy={t('components.NavMenu.home')}
              textClass={clsx(
                minimized && !drawerMax && classes.listItemTextMinimized
              )}
            >
              <SvgHome />
            </IconListLink>
            <IconListLink
              link={t('routes.portfolio')}
              iconClass={classes.wideIcon}
              copy={t('components.NavMenu.portfolio')}
              textClass={clsx(
                minimized && !drawerMax && classes.listItemTextMinimized
              )}
            >
              <FolderIcon className={classes.svgIcon} />
            </IconListLink>
            <IconListLink
              link={t('routes.messages')}
              copy={t('components.NavMenu.messages')}
              textClass={clsx(
                minimized && !drawerMax && classes.listItemTextMinimized
              )}
              showBadge={unreadMessageCount > 0}
              GhostLinkProps={{
                exact: false,
                isActive: (_, location) =>
                  location.pathname.includes(t('routes.messages')),
              }}
            >
              <DescriptionIcon className={classes.svgIcon} />
            </IconListLink>
            <IconListLink
              link={t('routes.upgrade')}
              copy={t('components.NavMenu.upgrade')}
              textClass={clsx(
                minimized && !drawerMax && classes.listItemTextMinimized
              )}
              chipText={
                !minimized && !upgradeActive && isInProductPageExperiment
                  ? 'New'
                  : null
              }
            >
              {(upgradeActive || !isInProductPageExperiment) && (
                <StarIcon className={classes.svgIcon} />
              )}
              {!upgradeActive && isInProductPageExperiment && (
                <img
                  src={StarGradient}
                  className={classes.starIcon}
                  alt="star"
                />
              )}
            </IconListLink>
          </List>
        </div>
        <div className={classes.listBottom}>
          {(!minimized || drawerMax) && (
            <>
              <NoOpErrorBoundary>
                <RailUpsellCardContainer />
              </NoOpErrorBoundary>
              <Divider className={classes.divider} />
              <List
                className={clsx(classes.list, classes.listAccount)}
                component="nav"
              >
                <IconListLink
                  link={t('routes.account')}
                  iconClass={classes.wideIcon}
                  copy={t('components.NavMenu.account')}
                >
                  <AccountCircleIcon className={classes.svgIcon} />
                </IconListLink>
                <IconListLink
                  link={t('routes.logout')}
                  copy={t('components.NavMenu.logOut')}
                  iconClass={classes.wideIcon}
                >
                  <SvgLogout className={classes.wideIcon} />
                </IconListLink>
              </List>
            </>
          )}
          {minimized && !drawerMax && (
            <IconButton className={classes.iconButton}>
              <ArrowForwardIosIcon
                className={classes.arrowForward}
                color="secondary"
                onClick={toggleDrawerMax(true)}
              />
            </IconButton>
          )}
        </div>
      </div>
    </>
  )
}

export default NavMenu
