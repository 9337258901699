import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'store'
import { upsellsError, upsellsFinishedLoading } from 'store/upsells/selectors'
import CatalogContainer from './CatalogContainer'
import ReviewsCarousel from './ReviewsCarousel'
import Skeleton from 'components/shared/Skeleton'

const ShoppingCartContainer: React.FC = () => {
  const finishedLoading = useSelector<AppState, boolean>((state) =>
    upsellsFinishedLoading(state.upsells)
  )
  const ordersFinishedLoading = useSelector<AppState, boolean>(
    (state) => state.orders.meta.FETCH_ORDERS_FOR_USER.loaded
  )
  const hasError = useSelector<AppState, boolean>((state) =>
    upsellsError(state.upsells)
  )

  if (hasError) {
    return <></>
  }

  if (!finishedLoading || !ordersFinishedLoading) {
    return <Skeleton components={[{ height: '60em', width: '100%' }]} />
  }
  return (
    <>
      <CatalogContainer />
      <ReviewsCarousel />
    </>
  )
}

export default ShoppingCartContainer
