import React, { useCallback, useState } from 'react'
import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

import { colors } from 'styles'
import CIOInfoModal from 'components/Modals/variants/CIOInfoModal'
import useUser from 'hooks/useUser'
import { GhostLinkButton } from 'components/shared/GhostLink'
import useViewInCIO from 'hooks/useViewInCIO'

import completedDocsSVG from 'assets/images/completed-docs.svg'
import tickIcon from 'assets/images/tick.svg'
import cioIcon from 'assets/images/career-io-white.svg'
import cioIconWithText from 'assets/images/career-io-with-text.svg'
import documentImg from 'assets/images/document-sample.png'

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      borderRadius: '0 0 6px 6px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      marginTop: '0',
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
      },
    },
    content: {
      display: 'flex',
      backgroundColor: '#EAF6FF',
      border: 'none',
      borderRadius: '.38em',
      padding: '2.5em',
      marginTop: '0',
      [theme.breakpoints.down('sm')]: {
        padding: '2em 1.25em',
      },
    },
    docContainer: {
      position: 'relative',
      marginRight: '3.75em',
      alignSelf: 'flex-start',
      width: 'auto',
      maxWidth: '10.75em',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    docImg: {
      width: '100%',
    },
    gradient: {
      position: 'absolute',
      width: '100%',
      background:
        'linear-gradient(0deg, #EAF6FF 0%, rgba(234, 246, 255, 0) 50%)',
      top: 0,
      bottom: 0,
    },
    main: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    title: {
      color: '#052a4d',
      fontSize: '1.625em',
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.375em',
      },
    },
    logo: {
      margin: '0 0.25em',
      marginBottom: '-0.48em',
    },
    description: {
      color: '#052a4d',
      fontSize: '1.125em',
      marginBottom: '2em',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1em',
        marginBottom: '1.5em',
      },
    },
    stepsTitle: {
      color: '#052a4d',
      fontSize: '0.875em',
      fontWeight: 700,
      letterSpacing: '1.8px',
      textTransform: 'uppercase',
    },
    steps: {
      margin: '0.5em 0',
    },
    step: {
      display: 'flex',
      alignItems: 'center',
      margin: '0.5em 0',
    },
    stepText: {
      color: '#052a4d',
      fontSize: '0.875em',
    },
    ctaContainer: {
      display: 'flex',
      margin: '0.75em 0',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    ctaPrimary: {
      marginRight: '0.625em',
      minWidth: '11.25em',
      '&:disabled': {
        backgroundColor: colors.blue[400],
      },
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        marginBottom: '0.75em',
      },
    },
    footer: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    poweredBy: {
      fontSize: '0.875em',
      marginRight: '0.5em',
    },
    emptyState: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',

      '& img': {
        marginBottom: '2rem',
      },
    },
    h2: {
      fontSize: '1.13em',
    },
    img: {
      marginBottom: '1em',
      maxWidth: '22em',
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'white',
        boxShadow: theme.shadows[6],
      },
    },
    cta: {
      marginTop: '1rem',
    },
  })
)

export const CompletedDocuments: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { allowed_in_cio: allowedInCIO } = useUser()
  const { loading, onViewDocs } = useViewInCIO()
  const [infoModalVisiblity, setInfoModalVisiblity] = useState(false)

  const jobSearchSteps: string[] = t(
    'components.Messaging.completedDocument.steps',
    { returnObjects: true }
  )

  const showInfoModal = useCallback(() => setInfoModalVisiblity(true), [])

  const closeInfoModal = useCallback(() => setInfoModalVisiblity(false), [])

  return (
    <>
      <Paper className={classes.paper} variant="outlined">
        {allowedInCIO ? (
          <Paper className={classes.content} variant="outlined">
            <Container disableGutters className={classes.docContainer}>
              <img
                src={documentImg}
                alt="document"
                className={classes.docImg}
              />
              <Box className={classes.gradient} />
            </Container>
            <Container disableGutters className={classes.main}>
              <Typography className={classes.title}>
                {t(`components.Messaging.completedDocument.title`)}
                <img alt="Career.io" className={classes.logo} src={cioIcon} />
                Career.io!
              </Typography>
              <Typography className={classes.description}>
                {t('components.Messaging.completedDocument.copy')}
              </Typography>
              <Typography className={classes.stepsTitle}>
                {t('components.Messaging.completedDocument.stepsTitle')}
              </Typography>
              <Container disableGutters className={classes.steps}>
                {jobSearchSteps.map((step) => (
                  <Box className={classes.step}>
                    <img src={tickIcon} alt="tick" />
                    <Typography className={classes.stepText}>{step}</Typography>
                  </Box>
                ))}
              </Container>
              <Container disableGutters className={classes.ctaContainer}>
                <Button
                  className={classes.ctaPrimary}
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={onViewDocs}
                >
                  {loading ? (
                    <CircularProgress style={{ color: '#fff' }} size={28} />
                  ) : (
                    t(`components.Messaging.completedDocument.viewDocuments`)
                  )}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={showInfoModal}
                >
                  {t('components.Messaging.completedDocument.learnMore')}
                </Button>
              </Container>
              <Container disableGutters className={classes.footer}>
                <Typography className={classes.poweredBy}>
                  {t('components.Messaging.completedDocument.poweredBy')}
                </Typography>
                <img src={cioIconWithText} alt="career.io" />
              </Container>
            </Container>
          </Paper>
        ) : (
          <Container className={classes.emptyState}>
            <img
              src={completedDocsSVG}
              alt="document"
              className={classes.img}
            />
            <Typography>
              {t(`components.Messaging.viewPastDocuments`)}
            </Typography>
            <GhostLinkButton
              variant="contained"
              color="primary"
              to={generatePath(t('routes.portfolio'))}
              className={classes.cta}
            >
              {t(`components.Messaging.viewPortfolio`)}
            </GhostLinkButton>
          </Container>
        )}
      </Paper>
      {infoModalVisiblity ? <CIOInfoModal onClose={closeInfoModal} /> : null}
    </>
  )
}
